<!--
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-09-10 09:43:36
 * @LastEditTime: 2019-09-29 14:49:19
 * @LastEditors: Please set LastEditors
 -->
<template>
    <div class="product">
        <txa></txa>
        <div class="con">
            <div class="left">
                <div class="tit">
                    <!-- <div style="display: flex;align-items: center;justify-content: center;">
                        产品模块
                    </div> -->
                    <ul>
                        <li v-for='(i,index) in text'  :key="index">
                            <p @click="dian_left_list(index)"  :class="{ 'left_biao':index == left_index}" >{{i.name}}</p>
                        </li>
                    </ul>
                    <!-- <p class="p2" @click="jinru_shouye"><img src="../assets/house.png">回到功能区</p> -->
                </div>
                <i class="el-icon-d-arrow-right"></i>
            </div>
            <div class="box">
                <router-view />
            </div>
        </div>
        <zongqiu class="xuanfu" v-drag></zongqiu>
    </div>
</template>

<script>
import kehu from './xiaoshou/Cusman'

import zongqiu from '../components/Zonganniu' //。
import kanban from './xiaoshou/Kanban'
import kanbanlist from './xiaoshou/Kanbanlist'
import txa from '../components/Txa'
export default {
  name: 'product',
  data () {
    return {
      text: [
        {
          name: '采购管理'
        },
        {
          name: '库存管理'
        }
      ],
      tit_list: [
        // '销售目标',
        // '产品目标',
        // '核算目标',
        // '风控目标',
        // '成长目标',
      ],
      inde: '0',
      left_index: '0',
      path: ''
    }
  },
  components: { txa, kehu, kanban, kanbanlist, zongqiu },
  created () {
    // if (sessionStorage.getItem("pc_mlbb_xiaoshou_biao") != null&&sessionStorage.getItem("pc_mlbb_xiaoshou_biao") != undefined&&sessionStorage.getItem("pc_mlbb_xiaoshou_biao") != '') {
    //     this.left_index = JSON.parse(sessionStorage.getItem("pc_mlbb_xiaoshou_biao"));
    // }else{
    //     this.left_index='0'
    // }
    this.jianting(this.$route.path)
    this.path = this.$route.path
  },
  mounted () {
  },
  watch: {
    $route () {
      let date = ''
      date = this.$route.fullPath
      this.jianting(date)
    }
  },
  methods: {
    jianting (i) {
      if (i == '/purchasing' || i == '/purchasingkb' || i == '/sqe' || i == '/price' || i == '/procurement_rules') {
        this.left_index = '0'
      } else if (i == '/inventory' || i == '/stock') {
        this.left_index = '1'
      }
    },
    // 左侧点击
    dian_left_list (index) {
      // sessionStorage.setItem("pc_mlbb_xiaoshou_biao", JSON.stringify(index));
      if ((this.left_index != 0 && index == 0) || (index == 0 && this.path != '/purchasing')) {
        this.$router.push('/purchasing')
      } else if ((this.left_index != 1 && index == 1) || (index == 1 && this.path != '/inventory')) {
        this.$router.push('/inventory')
      }
      this.left_index = index
      // if(index==0){
      //     this.$refs.dakai_sanqiu.dianji_fanhui()
      // }
    },
    dianji_guanbi () {
      this.$refs.qiqiu_guanbi.guanbi_qiu()
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.product {
    background:#F4F7FD;
    min-height: 100vh;
    .xuanfu{
        position: absolute;
        z-index: 111;
        top:0.3rem;
  		left:0.3rem;
    }
    .con{
        display: flex;
        justify-content: left;
        min-height: 100vh;
        .left{
            background: #fff;
            position: relative;
            position: fixed;
            top: 0;
            bottom: 0;
            left: 0;
            z-index: 111;
            width:0.4rem;
            transition: width 1s;
            -webkit-transition: width 1s; /* Safari */
            .tit{
                display: none;
                // div{
                //     height:0.8rem;
                //     // width:1.3rem;
                //     background:#9a86db;
                //     font-size: 0.22rem;
                //     color:#FFFFFF;
                //     text-align: center;
                //     line-height: 0.8rem;
                // }
                ul{
                    padding-top:1.8rem ;
                    li{
                        // border-bottom: 0.01rem solid #EEEEEE;
                        cursor: pointer;
                        p{
                            padding: 0.18rem 0;
                            text-align: center;
                            display: block;
                            line-height: 0.41rem;
                            font-size: 0.16rem;
                            color:#888;
                        }
                        .left_biao{
                            color:#fff;
                            background: #8140DA;
                        }
                    }

                }
                .p2{
                    display: flex;
                    color:#888888;
                    font-size: 0.14rem;
                    height:0.46rem;
                    width:2.05rem;
                    justify-content: center;
                    align-items: center;
                    border:0.01rem solid #888;
                    border-radius: 0.04rem;
                    position: absolute;
                    bottom: 0.3rem;
                    left:0.27rem;
                    cursor: pointer;
                    img{
                        margin-right: 0.06rem;
                        height:13.0.06rem;
                        width:13.0.06rem;
                    }
                }
            }
            &:hover{
                width:1.3rem;
                .tit{
                    display: block;
                }
                i{
                    display: none;
                }
            }
            i{
                position: absolute;
                display: block;
                font-size: 0.18rem;
                top:50%;
                left: 50%;
                margin-top: -0.18rem;
                margin-left: -0.09rem;
            }
        }
        .box{
            width:95%;
            min-height: 100%;
            margin-left:0.4rem;
            background:#F4F7FD;
        }
        // @media screen and (max-width: 1200px) {
        //     .box{
        //         width:1200px;
        //     }
        // }
    }
    @media screen and (max-width: 1290px) {
        .con{
            width:1290px;
        }
    }
    .qiqiu{
        position: absolute;
        right:0.88rem;
        top:0.2rem;
        z-index: 99;
    }
}
@media screen and (max-width: 1200px) {
  .aspreackeven {
    width:1200px;
  }
}
@media screen and (max-height: 716px) {
  .left {
    height:716px;
  }
}
</style>
